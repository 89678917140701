.search-bar-content {
    padding: 50px 0;
    background-color: #fff;
    border-radius: 50px;
}

.input-autocomplete-content{
    text-align: center;
}

.ant-select-selection-placeholder{
    padding: 5px 10px !important;
}