.App {
    text-align: center;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
    margin: 40px 0 40px 0;
}

.logo-sea {
    max-width: 300px;
}

.logo-vistalid-content{
    padding: 30px 50px 0 50px;
    display: flex;
    justify-content: start;
}
.logo-vistalid{
    max-width: 250px;
}

.header-content {
    margin: 10px 10px 40px 10px;
    background-color: #1974ba;
    border-radius: 50px;
    padding: 70px 100px;
}

.brand-content {
    text-align: right;
    position: relative;
}

.brand-content img {
    position: absolute;
    right: 1%;
    top: -110px;
    max-width: 500px;
    animation: App-logo-float infinite 3s ease-in-out;
}

.title-content h1 {
    color: #fff;
    font-size: 3.7em;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
}

.title-content span {
    font-size: 1.2em;
}

.title-content {
    text-align: left;
    color: #fff;
}

footer{
    background-color: #ffffff !important;
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 576px) {}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 768px) {}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {
    .ant-layout-content{
        padding: 0 10px !important;
    }
    .header-content {
        padding: 40px;
        margin: 40px 10px;
    }
    .brand-content img {
        max-width: 200px;
    }
    .title-content h1 {
        font-size: 1em;
    }
    .brand-content {
        position: relative;
        text-align: center;
    }

    .brand-content img {
        padding: 20px 0;
        position: relative;
        width: 60% !important;
        top: 0 !important;
    }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (max-width: 1200px) {
    .brand-content img {
        max-width: 350px;
        top: -50px;
    }
}

/*XX-Large devices (larger desktops, 1400px and up)*/
@media (max-width: 1400px) {
    .brand-content img {}
    .title-content h1 {
        font-size: 2em;
    }
}