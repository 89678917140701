.offers-content{
    position: relative;
    padding: 50px 40px;
    background-color: #fff;
    border-radius: 50px;
    margin: 50px 0;
}

.offers-content .logo-sea-2{
    position: absolute;
    opacity: 0.8;
    left: 130px;
    top: -80px;
    max-width: 220px;
}

.ant-steps{
    display: contents;
}

.ant-steps-item{
    min-width: 350px;
}

.switch-content{
    padding: 20px;
}

.offers-content .ant-steps-item-active{
    opacity: 1 !important;
}

.offers-content .ant-steps-item{
    opacity: 0.2;
}

.ant-steps-item-description{
    max-width: 250px !important;
}

.ant-ribbon-placement-end{
    display: none;
    transition: 1s ease-in;
}

.ribbon-active{
    display: block;
}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {
    .offers-content .logo-sea-2{
        max-width: 200px;
        left: 100px;
        top: -100px;
    }
}